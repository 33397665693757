const headerContent = {
  "/": {
    title: "Welcome!",
    subtitle: "My name is Matthew Mitchell",
    body:
      "Software Developer",
  },
  "/easygames": {
    title: "Easy Games",
    subtitle: "Gameplay Programmer",
    body:
      "Remote",
  },
  "/oombly": {
    title: "Oombly Interactive",
    subtitle: "Software Developer",
    body: "Remote",
  },
  "/denali": {
    title: "Denali Advanced Integrations",
    subtitle: "Software Development Engineer in Test",
    body: "Redmond, WA",
  },
  "/spectralux": {
    title: "Spectralux Avionics",
    subtitle: "Software Test Engineer",
    body: "Redmond, WA",
  },
  "/monscierge": {
    title: "Monscierge",
    subtitle: "US Support Lead / Jr. iOS Dev",
    body: "Oklahoma City, OK",
  },
  "/tinyent": {
    title: "tinyEnt Studios",
    subtitle: "Founder",
    body: "Redmond, WA",
  },
  "/g33ks": {
    title: "G33ks",
    subtitle: "Electronics Repair Store Owner",
    body: "Jena, LA",
  },
  "/creamed": {
    title: "Creamed",
    subtitle: "Mobile Game",
    body: "iOS and Android",
  },
  "/thrusterbuster": {
    title: "Thruster Buster",
    subtitle: "Mobile Game",
    body: "iOS and Android",
  },
  "/codingdojo": {
    title: "Coding Dojo",
    subtitle: "Python/iOS/MEAN Tech Stacks",
    body: "Bellevue, WA",
  },
  "/gpagnw": {
    title: "Greyhound Pets of America Greater Northwest",
    subtitle: "Web Development",
    body: "Nonprofit",
  },
  "/duolingo": {
    title: "Duolingo",
    subtitle: "German",
    body: "Danke für's vorbeikommen!",
  },
  "/spartan": {
    title: "Spartan Races",
    subtitle: "Obstacle Course",
    body: "Sprint, Super and Beast Finisher",
  },
  "/chucktester": {
    title: "Chucktester",
    subtitle: "by Matthew Mitchell",
    body: "Web Portfolio",
  },
  "/tap": {
    title: "Tap To Blast",
  },
  "/404": {
    title: "Uh Oh...",
    subtitle: "404 - Page not found!",
    body: "Please click the Home icon to return!",
  },

};

export default headerContent;
