import React from "react";
import "./styles/App.scss";
import Container from "react-bootstrap/Container";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Component imports
import Main from "components/common/Main";
import ScrollToTop from "components/common/ScrollToTop";

function App() {
  return (
    <Router>
      <Container style={{ textAlign: "center" }}>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact>
            <Main componentToRender="Home" />
          </Route>
          <Route path="/easygames" exact>
            <Main componentToRender="EasyGames" />
          </Route>
          <Route path="/oombly" exact>
            <Main componentToRender="OomblyInteractive" />
          </Route>
          <Route path="/denali" exact>
            <Main componentToRender="Denali" />
          </Route>
          <Route path="/spectralux" exact>
            <Main componentToRender="Spectralux" />
          </Route>
          <Route path="/monscierge" exact>
            <Main componentToRender="Monscierge" />
          </Route>
          <Route path="/tinyEnt" exact>
            <Main componentToRender="TinyEnt" />
          </Route>
          <Route path="/g33ks" exact>
            <Main componentToRender="G33ks" />
          </Route>
          <Route path="/creamed" exact>
            <Main componentToRender="Creamed" />
          </Route>
          <Route path="/thrusterbuster" exact>
            <Main componentToRender="ThrusterBuster" />
          </Route>
          <Route path="/codingdojo" exact>
            <Main componentToRender="CodingDojo" />
          </Route>
          <Route path="/gpagnw" exact>
            <Main componentToRender="GPAGNW" />
          </Route>
          <Route path="/duolingo" exact>
            <Main componentToRender="Duolingo" />
          </Route>
          <Route path="/spartan" exact>
            <Main componentToRender="Spartan" />
          </Route>
          <Route path="/chucktester" exact>
            <Main componentToRender="Chucktester" />
          </Route>
          <Route path="/tap" exact>
            <Main componentToRender="UnityViewer" />
          </Route>
          <Route path="/*" exact>
            <Main componentToRender="NotFound" />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
