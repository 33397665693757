import React from 'react';

const UnityViewer = () => {
  return (
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe
        title="Unity WebGL Build"
        src="/unity/index.html" // Path to the Unity build's index.html
        style={{ width: '100%', height: '100%', border: 'none' }}
        allowFullScreen
      />
    </div>
  );
};

export default UnityViewer;